import PropTypes from "prop-types";
import { Children, cloneElement, Component } from "react";

export class Tabs extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			activeTabIndex: this.getTabIndexFromHash(),
		};

		window.onhashchange = () => {
			this.setState({
				activeTabIndex: this.getTabIndexFromHash(),
			});
		};

		this.handleTabClick = this.handleTabClick.bind(this);
	}

	getTabIndexFromHash() {
		let activeTabIndex;

		if (window.location.hash.indexOf("#tab-") === 0) {
			activeTabIndex = parseInt(window.location.hash.substr("#tab-".length));

			if (
				isNaN(activeTabIndex) ||
				activeTabIndex > Children.toArray(this.props.children).filter(child => child.props.visible).length - 1
			) {
				activeTabIndex = this.props.defaultActiveTabIndex;
			}
		} else {
			activeTabIndex = this.props.defaultActiveTabIndex;
		}

		if (this.props.onActiveTabChange) {
			this.props.onActiveTabChange(activeTabIndex);
		}

		return activeTabIndex;
	}

	handleTabClick(tabIndex) {
		if (!this.props.canChange) {
			return;
		}
		this.setState({
			activeTabIndex: tabIndex,
		});
		window.location.hash = `tab-${tabIndex}`;
	}

	// Encapsulate <Tabs/> component API as props for <Tab/> children
	renderChildrenWithTabsApiAsProps() {
		return Children.map(
			Children.toArray(this.props.children).filter(child => child.props.visible),
			(child, index) =>
				child &&
				cloneElement(child, {
					onClick: this.handleTabClick,
					tabIndex: index,
					isActive: index === this.state.activeTabIndex,
					isDisabled: !this.props.canChange,
				}),
		);
	}

	// Render current active tab content
	renderActiveTabContent() {
		const children = Children.toArray(this.props.children).filter(child => child.props.visible);
		const { activeTabIndex } = this.state;
		if (children[activeTabIndex]) {
			return children[activeTabIndex].props.children;
		}
	}

	render() {
		return (
			<div>
				<ul className="flex tabs shadow -mt-6">{this.renderChildrenWithTabsApiAsProps()}</ul>
				<div className="tab-content">{this.renderActiveTabContent()}</div>
			</div>
		);
	}
}

Tabs.propTypes = {
	children: PropTypes.node.isRequired,
	defaultActiveTabIndex: PropTypes.number,
	onActiveTabChange: PropTypes.func,
	canChange: PropTypes.bool,
};

Tabs.defaultProps = {
	defaultActiveTabIndex: 0,
	canChange: true,
};
