import { useContext } from "react";
import MediaLibraryContext from "./MediaLibraryContext";
import MediaLibraryPicture from "./MediaLibraryPicture";

export default function MediaLibraryPictures() {
	const { media } = useContext(MediaLibraryContext);

	return (
		<div className="flex flex-wrap">
			{media.map(image => (
				<MediaLibraryPicture key={image.id} image={image} />
			))}
		</div>
	);
}
