import { createLoader } from "../../../../../../js/react/components/general/notifications";
import axios from "axios";
import classNames from "classnames";
import { useCallback, useContext, useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import DeleteDialog from "../../../../../../js/react/components/general/DeleteDialog";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import Translate from "../../../../../../js/react/components/general/Translate";
import MediaLibrary from "./MediaLibrary";
import MediaLibraryContext from "./MediaLibraryContext";
import MediaLibraryFolderDialog from "./MediaLibraryFolderDialog";
import { route } from "../../../../../../js/helpers";

export default function MediaLibraryFolders() {
	const { mode, type, folders, folderCounts, currentFolder, setCurrentFolder, refreshFolders } =
		useContext(MediaLibraryContext);
	const [editingId, setEditingId] = useState(null);
	const [deleting, setDeleting] = useState(null);

	const handleDelete = useCallback(async () => {
		if (!deleting) {
			return;
		}
		const deleteCurrent = deleting.id === currentFolder;
		const loader = createLoader(uctrans("general.:item_is_being_deleted", {}, { item: "media.mediafolder.singular" }));
		try {
			await axios.delete(route(`crm.media_folder.destroy`, deleting.id));
			if (deleteCurrent) {
				setCurrentFolder(null);
			}
			loader.success(uctrans("general.deleted_:item", {}, { item: "media.mediafolder.singular" }));
			refreshFolders();
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}
		setDeleting(null);
	}, [deleting, currentFolder, refreshFolders, setCurrentFolder]);

	return (
		<div className="media-folder-browser border-l border-grey pl-6 lg:pl-12">
			<h5 className="inline-block">
				<Translate content="media.mediafolder.plural" />
			</h5>

			{mode === MediaLibrary.MODE_LIBRARY && (
				<button className="link flex" onClick={() => setEditingId(-1)}>
					<EvaIcon type="plus" width="16" height="16" fill="#009286" className="mr-2" />
					<Translate content="general.add_:item" transReplaces={{ item: `media.mediafolder.singular` }} />
				</button>
			)}

			<div
				className={classNames("media-folder mt-12 cursor-pointer", {
					"font-bold text-primary": currentFolder === null,
				})}
				onClick={() => setCurrentFolder(null)}>
				<Translate content="general.all_:items" transReplaces={{ items: `media.${type}.plural` }} /> (
				{folderCounts.total || 0})
			</div>

			<div
				className={classNames("media-folder mt-2 cursor-pointer", {
					"font-bold text-primary": currentFolder === -1,
				})}
				onClick={() => setCurrentFolder(-1)}>
				<Translate content="general.no_:item" transReplaces={{ item: "media.mediafolder.singular" }} /> (
				{folderCounts[""] || 0})
			</div>

			{folders.map(folderItem => (
				<div
					className={classNames("media-folder items-center my-3 flex border-b border-grey-light", {
						"font-bold text-primary": currentFolder === folderItem.id,
					})}
					key={folderItem.id}>
					<span onClick={() => setCurrentFolder(folderItem.id)} className="flex-grow cursor-pointer flex my-3">
						<EvaIcon type="folder" fill="#D7F0EE" width="20" height="20" />
						&nbsp;{folderItem.name} ({folderCounts[folderItem.id] || 0})
					</span>
					{mode === MediaLibrary.MODE_LIBRARY && (
						<>
							<a onClick={() => setEditingId(folderItem.id)} className="mr-3">
								<EvaIcon type="edit" fill="#9c9c9c" width="16" height="16" />
							</a>
							<a onClick={() => setDeleting(folderItem)}>
								<EvaIcon type="trash-2" fill="#9c9c9c" width="16" height="16" />
							</a>
						</>
					)}
				</div>
			))}

			<MediaLibraryFolderDialog
				isOpen={editingId !== null}
				folder={folders.find(folder => folder.id === editingId)}
				onCancel={() => setEditingId(null)}
				onConfirm={() => {
					setEditingId(null);
					refreshFolders();
				}}
			/>

			<DeleteDialog
				isOpen={deleting !== null}
				onCancel={() => setDeleting(null)}
				onConfirm={handleDelete}
				deleteData={{
					message: uctrans(
						"general.confirm_delete_:item_:label",
						{ label: deleting && deleting.name },
						{ item: "media.mediafolder.singular" },
					),
					errors: [],
					warnings: [],
				}}
			/>
		</div>
	);
}
