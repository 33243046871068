import PropTypes from "prop-types";
import { useContext, useState } from "react";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import MediaLibraryContext from "./MediaLibraryContext";
export default function MediaLibraryVideo({ video }) {
	const { selected, onMediaClick } = useContext(MediaLibraryContext);

	const [hovering, setHovering] = useState(false);

	return (
		<div
			className="pr-8 pt-8 w-1/3 max-h-fit-content"
			onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}>
			<div
				onClick={() => onMediaClick(video)}
				className={`relative h-48 cursor-pointer ${
					selected.indexOf(video.id) !== -1 ? "border-2 border-primary shadow" : "border border-grey"
				}`}>
				<div className="h-full w-full text-center">
					{hovering && (
						<span className="absolute top-0 inset-x-0 bg-grey-darker text-white text-center p-4 opacity-75 truncate z-10">
							{video.name}
						</span>
					)}
					{video.thumbnail_url ? (
						<img className="absolute inset-0 m-auto max-h-full max-w-full" src={video.thumbnail_url} alt={video.name} />
					) : (
						<div className="flex flex-col justify-around w-full h-full">
							<EvaIcon fill="#9c9c9c" type="video-outline" width="32" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

MediaLibraryVideo.propTypes = {
	video: PropTypes.object.isRequired,
};
