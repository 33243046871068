import { useContext } from "react";
import MediaLibraryContext from "./MediaLibraryContext";
import MediaLibraryAnimation from "./MediaLibraryAnimation";

export default function MediaLibraryAnimations() {
	const { media } = useContext(MediaLibraryContext);

	return (
		<div className="flex flex-wrap">
			{media.map(animation => (
				<MediaLibraryAnimation key={animation.id} animation={animation} />
			))}
		</div>
	);
}
