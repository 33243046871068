import { useContext } from "react";
import MediaLibraryContext from "./MediaLibraryContext";
import MediaLibraryVideo from "./MediaLibraryVideo";

export default function MediaLibraryVideos() {
	const { media } = useContext(MediaLibraryContext);

	return (
		<div className="flex flex-wrap">
			{media.map(video => (
				<MediaLibraryVideo key={video.id} video={video} />
			))}
		</div>
	);
}
