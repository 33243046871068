import PropTypes from "prop-types";
import { memo } from "react";
import ContentLoader from "react-content-loader";
import LazyLoad from "react-lazyload";

const MediaLibraryPictureLoader = ({ image, overflow }) => (
	<LazyLoad
		debounce
		once
		overflow={overflow}
		placeholder={
			<div className="w-full h-full">
				<ContentLoader className="w-full h-full" />
			</div>
		}>
		<img
			className="absolute inset-0 m-auto max-h-full max-w-full"
			src={`${image.thumbnail_url}?t=${Date.now()}`}
			alt={image.name}
		/>
	</LazyLoad>
);

MediaLibraryPictureLoader.propTypes = {
	image: PropTypes.object.isRequired,
	overflow: PropTypes.bool,
};

MediaLibraryPictureLoader.defaultProps = {
	overflow: false,
};

export default memo(MediaLibraryPictureLoader);
