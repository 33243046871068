import PropTypes from "prop-types";
import { memo } from "react";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";

const BreadCrumbs = memo(({ breadCrumbs }) => {
	if (!breadCrumbs) {
		return null;
	}
	return (
		<div className="breadcrumbs-container">
			<h6>
				{breadCrumbs.map((breadCrumb, index) => {
					if (Object.prototype.hasOwnProperty.call(breadCrumb, "route")) {
						return (
							<span key={index}>
								{index !== 0 && (
									<span className="breadcrumb-icon">
										<EvaIcon type="arrowhead-right" height="10" width="10" fill="#9c9c9c" />
									</span>
								)}
								<a href={breadCrumb.route} className="link">
									{breadCrumb.label}
								</a>
							</span>
						);
					} else {
						return (
							<span key={index}>
								{index !== 0 && (
									<span className="breadcrumb-icon">
										<EvaIcon type="arrowhead-right" height="10" width="10" fill="#9c9c9c" />
									</span>
								)}
								{breadCrumb.label}
							</span>
						);
					}
				})}
			</h6>
		</div>
	);
});

BreadCrumbs.displayName = "BreadCrumbs";

export default BreadCrumbs;

BreadCrumbs.propTypes = {
	breadCrumbs: PropTypes.array,
};
