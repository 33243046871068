import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import ConfirmDialog from "../../../../../../js/react/components/general/ConfirmDialog";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import Select from "../../../../../../js/react/components/general/Select";
import MediaLibraryContext from "./MediaLibraryContext";
import { createLoader, error } from "../../../../../../js/react/components/general/notifications";
import { route } from "../../../../../../js/helpers";

export default function MediaLibraryMoveButton() {
	const { folders, currentFolder, refreshMedia, type, selected } = useContext(MediaLibraryContext);

	const [dialogOpen, setDialogOpen] = useState(false);
	const [moveTo, setMoveTo] = useState(null);

	const handleButtonClick = useCallback(() => {
		setMoveTo(null);
		setDialogOpen(true);
	}, []);

	const handleMove = useCallback(async () => {
		setDialogOpen(false);
		if (moveTo === null) {
			error(uctrans("general.no_:item_selected", {}, { item: "media.mediafolder.singular" }));
			return;
		}
		let loader;
		if (selected.length > 1) {
			loader = createLoader(uctrans("general.:items_are_being_moved", {}, { items: `media.${type}.plural` }));
		} else {
			loader = createLoader(uctrans("general.:item_is_being_moved", {}, { item: `media.${type}.singular` }));
		}
		try {
			await axios.patch(route(`crm.${type}.move`), { folder: moveTo.value, ids: selected });
			if (selected.length > 1) {
				loader.success(uctrans("general.moved_:items", {}, { items: `media.${type}.plural` }));
			} else {
				loader.success(uctrans("general.moved_:item", {}, { item: `media.${type}.singular` }));
			}
			refreshMedia();
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}
	}, [moveTo, refreshMedia, selected, type]);

	const additionalFolders = [];
	if (currentFolder !== -1) {
		additionalFolders.push({
			value: -1,
			label: uctrans("general.no_:item", {}, { item: "media.mediafolder.singular" }),
		});
	}

	return (
		<>
			<IconButton
				icon="arrow-forward"
				className="ml-2"
				fill="#ffffff"
				content={uctrans("general.move")}
				onClick={handleButtonClick}
			/>

			<ConfirmDialog
				isOpen={dialogOpen}
				onConfirm={handleMove}
				onCancel={() => setDialogOpen(false)}
				title={uctrans(
					"media.select_folder_to_move_:count_:media",
					{ count: selected.length },
					{ media: `media.${type}.${selected.length > 1 ? "plural" : "singular"}` },
				)}>
				<Select
					options={[
						...additionalFolders,
						...folders
							.filter(folder => folder.id !== currentFolder)
							.map(folder => ({ value: folder.id, label: folder.name })),
					]}
					value={moveTo}
					onChange={selected => setMoveTo(selected)}
					isClearable={false}
				/>
			</ConfirmDialog>
		</>
	);
}
