import PropTypes from "prop-types";

export default function Animation({ animation, onEnded }) {
	if (animation) {
		return (
			<video
				className="h-full w-full"
				preload="metadata"
				onEnded={() => {
					onEnded && onEnded();
				}}
				muted
				disablePictureInPicture
				poster={animation.thumbnail_url ?? null}
				id={`animation-${animation.id}`}>
				<source src={animation.show_url} type={animation.show_mime} />
				<source src={animation.fallback_url} type={animation.fallback_mime} />
			</video>
		);
	} else {
		return null;
	}
}

Animation.propTypes = {
	animation: PropTypes.object,
	onEnded: PropTypes.func,
};
