import axios from "axios";
import { useCallback, useContext, useRef } from "react";
import { route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import { createLoader } from "../../../../../../js/react/components/general/notifications";
import FileInput from "../../forms/FileInput";
import MediaLibrary from "./MediaLibrary";
import MediaLibraryContext from "./MediaLibraryContext";

export default function MediaLibraryUploadButton() {
	const { mode, type, currentFolder, refreshMedia, onMediaClick } = useContext(MediaLibraryContext);

	const loader = useRef(null);

	const onBeforeUpload = useCallback(() => {
		loader.current = createLoader(uctrans("file.file_uploading"));
	}, [loader]);

	const onUpload = useCallback(
		async files => {
			if (files.length <= 0) {
				return;
			}

			try {
				const data = {
					files,
				};

				if (currentFolder > 0) {
					data.folder = currentFolder;
				}

				const response = await axios.post(route(`crm.${type}.store`), data);
				if (files.length === 1 && mode === MediaLibrary.MODE_SELECTOR) {
					onMediaClick(response.data[0]);
				} else {
					refreshMedia();
				}

				if (loader) {
					loader.current.success(uctrans("file.file_uploading_complete"));
				}
			} catch (error) {
				console.error(error);
				loader.current.failure(
					uctrans("file.file_uploading_failed") +
						(error.response.data.message ? `: ${error.response.data.message}` : ""),
				);
			}
		},
		[currentFolder, type, mode, onMediaClick, refreshMedia],
	);

	const onFailure = useCallback(() => {
		if (loader.current) {
			loader.current.failure(uctrans("file.file_uploading_failed"));
		}
	}, [loader]);

	return (
		<FileInput
			onChange={onUpload}
			onBeforeUpload={onBeforeUpload}
			fileType={type}
			useLoaders={false}
			clearAfterUpload
			onFailure={onFailure}
			acceptedExtensions={MediaLibrary.allowedExtensions[type]}
			maxFiles={0}
		/>
	);
}
