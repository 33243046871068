import LabeledCheckbox from "../../../../../../js/react/components/general/form/LabeledCheckbox";
import { createLoader, error } from "../../../../../../js/react/components/general/notifications";
import axios from "axios";
import forEach from "lodash/forEach";
import upperFirst from "lodash/upperFirst";
import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import ConfirmDialog from "../../../../../../js/react/components/general/ConfirmDialog";
import Select from "../../../../../../js/react/components/general/Select";
import Translate from "../../../../../../js/react/components/general/Translate";
import FileInput from "../../forms/FileInput";
import MediaLibrary from "./MediaLibrary";
import MediaLibraryContext from "./MediaLibraryContext";
import { route } from "../../../../../../js/helpers";

export default function MediaLibraryMediaDialog(props) {
	const { type, marketingThemes } = useContext(MediaLibraryContext);
	const [name, setName] = useState(null);
	const [file, setFile] = useState([]);
	const [thumbnail, setThumbnail] = useState([]);
	const [fallback, setFallback] = useState([]);
	const [currentTheme, setCurrentTheme] = useState(null);

	useEffect(() => {
		setThumbnail(props.media.thumbnail ? [props.media.thumbnail] : []);
		setName(props.media.name ? props.media.name : null);
		setFallback(props.media.fallback ? [props.media.fallback] : []);
		setCurrentTheme(props.media.marketing_theme_id ? [props.media.marketing_theme_id] : null);
		setFile(props.media.file ? [props.media.file] : []);
	}, [props.media]);

	const handleConfirm = useCallback(async () => {
		const loader = createLoader(uctrans("general.save_:item", {}, { item: `media.${type}.singular` }));

		try {
			await axios.put(route(`crm.${type}.update`, props.media.id), {
				name,
				file,
				thumbnail,
				fallback,
				marketing_theme_id: currentTheme,
			});
			loader.success(uctrans("general.saved_:item", {}, { item: `media.${type}.singular` }));
			props.onConfirm();
		} catch (exception) {
			if (exception.response && exception.response.status === 422) {
				if (Object.prototype.hasOwnProperty.call(exception.response.data, "errors")) {
					let first = true;
					forEach(exception.response.data.errors, message => {
						if (first && loader) {
							loader.failure(upperFirst(message[0]));
							first = false;
						} else {
							error(upperFirst(message[0]));
						}
					});
				}
			} else if (exception.message) {
				loader.failure(exception.message);
			}
			props.onCancel();
		}
	}, [type, props, name, file, thumbnail, fallback, currentTheme]);

	return (
		<ConfirmDialog
			isOpen={props.isOpen}
			onConfirm={handleConfirm}
			onCancel={props.onCancel}
			title={uctrans("general.edit_:item", {}, { item: `media.${type}.singular` })}
			confirmButtonLabel={uctrans("general.save_:item", {}, { item: `media.${type}.singular` })}
			cancelButtonLabel={uctrans("general.cancel")}>
			<span className="form-full">
				<label htmlFor="folder_name">
					<Translate content="general.name" />
				</label>
				<input id="folder_name" type="text" value={name} onChange={event => setName(event.target.value)} />
			</span>
			{props.isDocument && marketingThemes && (
				<span className="form-full">
					<label htmlFor="marketing_theme">
						<Translate content="marketing.themes.singular" />
					</label>
					<Select
						value={marketingThemes.filter(({ value }) => Number(value) === Number(currentTheme ? currentTheme : null))}
						options={marketingThemes}
						name="marketing_theme_id"
						onChange={event => setCurrentTheme(event ? event.value : null)}
					/>
				</span>
			)}

			<span className="form-full">
				<label>
					<Translate content="media.fields.file" />
				</label>
				<FileInput
					id={props.media.id}
					initialFiles={file}
					fileType={type}
					onChange={files => setFile(files)}
					acceptedExtensions={MediaLibrary.allowedExtensions[type]}
				/>
			</span>
			{props.isAnimation && (
				<>
					<span className="form-full">
						<label>
							<Translate content="media.fields.thumbnail" />
						</label>
						<FileInput
							id={props.media.id}
							initialFiles={thumbnail}
							fileType="image"
							onChange={files => setThumbnail(files)}
							acceptedExtensions={MediaLibrary.allowedExtensions.image}
						/>
					</span>
					<span className="form-full">
						<label>
							<Translate content="media.fields.fallback" />
						</label>
						<FileInput
							id={props.media.id}
							initialFiles={fallback}
							fileType={type}
							onChange={files => setFallback(files)}
							acceptedExtensions={MediaLibrary.allowedExtensions[type]}
						/>
					</span>
				</>
			)}
			{props.isDocument && (
				<LabeledCheckbox
					label={uctrans("media.fields.public")}
					checked={file[0]?.public}
					onChange={e => setFile([{ ...file[0], public: e.target.checked }])}
				/>
			)}
		</ConfirmDialog>
	);
}

MediaLibraryMediaDialog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	media: PropTypes.object.isRequired,
	onCancel: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	isDocument: PropTypes.bool,
	isAnimation: PropTypes.bool,
};
