import { createLoader } from "../../../../../../js/react/components/general/notifications";
import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import MediaLibraryContext from "./MediaLibraryContext";

export default function MediaLibrarySyncButton() {
	const { refreshMedia } = useContext(MediaLibraryContext);

	const [syncing, setSyncing] = useState(false);

	const sync = useCallback(async () => {
		setSyncing(true);
		const loader = createLoader(uctrans("media.syncing_with_kaltura"));
		try {
			await axios.post(route(`crm.video.sync`));
			refreshMedia();
			loader.success(uctrans("media.synced_with_kaltura"));
		} catch (error) {
			console.error(error);
			loader.failure(uctrans("media.syncing_with_kaltura_failed"));
		}
		setSyncing(false);
	}, [refreshMedia]);

	return (
		<IconButton
			icon="sync-outline"
			className="ml-2"
			fill="#ffffff"
			content={uctrans("media.sync_with_kaltura")}
			onClick={sync}
			disabled={syncing}
		/>
	);
}
