import { createContext } from "react";

export const CmsTypesContext = createContext({
	pageTypes: {},
	partTypes: {},
	attributeTypes: {},
	resourceTypes: {},
	tagTypes: {},
	imageOptions: [],
	videoOptions: [],
	animationOptions: [],
	documentOptions: [],
	pageOptions: [],
	newsitemOptions: [],
	CTLandingOptions: [],
	questionnaireOptions: [],
	definitionOptions: [],
	interestTypes: [],
});
