import { useContext } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import SearchBar from "../../../../../../js/react/components/general/SearchBar";
import Select from "../../../../../../js/react/components/general/Select";
import MediaLibraryContext from "./MediaLibraryContext";

export default function MediaLibraryFilters() {
	const { filters, setFilter } = useContext(MediaLibraryContext);

	const options = [
		{ value: "name", label: uctrans("media.fields.name") },
		{ value: "created_at", label: uctrans("media.fields.created_at") },
		{ value: "updated_at", label: uctrans("media.fields.updated_at") },
	];

	return (
		<div className="form-container flex">
			<div className="form-1-2 relative media-searchbar">
				<label>&nbsp;</label>
				<SearchBar onChange={e => setFilter("query", e.target.value)} value={filters.query} />
			</div>

			<div className="form-1-4 ml-auto">
				<label>{uctrans("general.sort_by")}</label>
				<Select
					onChange={selected => setFilter("sort", selected.value)}
					value={options.find(option => option.value === filters.sort)}
					options={options}
					isClearable={false}
				/>
			</div>
		</div>
	);
}
