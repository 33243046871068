import PropTypes from "prop-types";
import GeneralFileInput from "../../../../../js/react/components/general/form/FileInput";

export default function FileInput(props) {
	return (
		<GeneralFileInput
			storeRoute={props.storeRoute}
			fileType={props.fileType}
			public={props.public}
			existingFileDownloadRoute={props.existingFileDownloadRoute}
			newFileDownloadRoute={props.newFileDownloadRoute}
			maxFiles={props.maxFiles}
			acceptedExtensions={props.acceptedExtensions}
			initialFiles={props.initialFiles}
			onChange={props.onChange}
			onBeforeUpload={props.onBeforeUpload}
			onAfterUpload={props.onAfterUpload}
			onFailure={props.onFailure}
			clearAfterUpload={props.clearAfterUpload}
			useLoaders={props.useLoaders}
			additionalFileDownloadRouteProps={props.additionalFileDownloadRouteProps}
			disabled={props.disabled}
		/>
	);
}

FileInput.propTypes = {
	storeRoute: PropTypes.string,
	newFileDownloadRoute: PropTypes.string,
	fileType: PropTypes.oneOf(["image", "document", "animation"]),
	existingFileDownloadRoute: PropTypes.string,
	maxFiles: PropTypes.number,
	acceptedExtensions: PropTypes.array,
	initialFiles: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			filename: PropTypes.string.isRequired,
		}),
	),
	public: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onBeforeUpload: PropTypes.func,
	onAfterUpload: PropTypes.func,
	onFailure: PropTypes.func,
	clearAfterUpload: PropTypes.bool,
	useLoaders: PropTypes.bool,
	additionalFileDownloadRouteProps: PropTypes.object,
	disabled: PropTypes.bool,
};

FileInput.defaultProps = {
	storeRoute: "crm.files.store",
	newFileDownloadRoute: "crm.files.download",
	maxFiles: 1,
	initialFiles: [],
	public: false,
	clearAfterUpload: false,
	useLoaders: true,
	disabled: false,
};
