import { createLoader } from "../../../../../../js/react/components/general/notifications";
import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import DeleteDialog from "../../../../../../js/react/components/general/DeleteDialog";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import MediaLibraryContext from "./MediaLibraryContext";
import { route } from "../../../../../../js/helpers";

export default function MediaLibraryDeleteButton() {
	const { refreshMedia, type, selected } = useContext(MediaLibraryContext);

	const [deleteWarnings, setDeleteWarnings] = useState(null);
	const [dialogOpen, setDialogOpen] = useState(false);

	const startDelete = useCallback(async () => {
		// Retrieve warnings
		try {
			const response = await axios.get(route(`crm.${type}.delete-check`), { params: { ids: selected } });
			setDeleteWarnings(response.data);
		} catch (error) {
			console.error(error);
			setDeleteWarnings([]);
		}

		setDialogOpen(true);
	}, [type, selected]);

	const handleDelete = useCallback(async () => {
		setDialogOpen(false);
		let loader;
		if (selected.length > 1) {
			loader = createLoader(uctrans("general.:items_are_being_deleted", {}, { items: `media.${type}.plural` }));
		} else {
			loader = createLoader(uctrans("general.:item_is_being_deleted", {}, { item: `media.${type}.singular` }));
		}
		try {
			await axios.delete(route(`crm.${type}.destroy-many`), { params: { ids: selected } });
			if (selected.length > 1) {
				loader.success(uctrans("general.deleted_:items", {}, { items: `media.${type}.plural` }));
			} else {
				loader.success(uctrans("general.deleted_:item", {}, { item: `media.${type}.singular` }));
			}
			refreshMedia();
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}
	}, [refreshMedia, selected, type]);

	return (
		<>
			<IconButton icon="trash-2-outline" fill="#ffffff" content={uctrans("general.delete")} onClick={startDelete} />

			<DeleteDialog
				isOpen={dialogOpen}
				onCancel={() => setDialogOpen(false)}
				onConfirm={handleDelete}
				deleteData={{
					message: uctrans(
						"general.confirm_delete_:count_:items",
						{ count: selected.length },
						{ items: `media.${type}.${selected.length > 1 ? "plural" : "singular"}` },
					),
					errors: [],
					warnings: deleteWarnings || [],
				}}
			/>
		</>
	);
}
