import PropTypes from "prop-types";
import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import DeleteDialog from "../../../../../../js/react/components/general/DeleteDialog";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import { createLoader } from "../../../../../../js/react/components/general/notifications";
import Animation from "../Animation";
import MediaLibrary from "./MediaLibrary";
import MediaLibraryContext from "./MediaLibraryContext";
import MediaLibraryMediaDialog from "./MediaLibraryMediaDialog";

export default function MediaLibraryAnimation({ animation }) {
	const { mode, refreshMedia, selected, onMediaClick } = useContext(MediaLibraryContext);

	const [hovering, setHovering] = useState(false);
	const [playing, setPlaying] = useState(false);
	const [editing, setEditing] = useState(false);
	const [deleteWarnings, setDeleteWarnings] = useState(null);
	const [deleting, setDeleting] = useState(false);

	const togglePlayState = () => {
		setPlaying(prevState => !prevState);
		const video = document.getElementById(`animation-${animation.id}`);
		if (playing) {
			video.pause();
		} else {
			video.play();
		}
	};

	const startDelete = useCallback(async () => {
		if (deleteWarnings === null) {
			// Retrieve warnings
			try {
				const response = await axios.get(route(`crm.animation.delete-check`), { params: { ids: [animation.id] } });
				setDeleteWarnings(response.data);
			} catch (error) {
				console.error(error);
				setDeleteWarnings([]);
			}
		}

		setDeleting(true);
	}, [animation.id, deleteWarnings, setDeleteWarnings, setDeleting]);

	const handleDelete = useCallback(async () => {
		setDeleting(false);
		const loader = createLoader(uctrans("general.:item_is_being_deleted", {}, { item: "media.animation.singular" }));
		try {
			await axios.delete(route(`crm.animation.destroy`, animation.id));
			loader.success(uctrans("general.deleted_:item", {}, { item: "media.animation.singular" }));
			refreshMedia();
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}
	}, [animation.id, refreshMedia, setDeleting]);

	return (
		<div
			className="pr-8 pt-8 w-1/3 max-h-fit-content"
			onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}>
			<div
				onClick={() => onMediaClick(animation)}
				className={`relative h-48 cursor-pointer ${
					selected.indexOf(animation.id) !== -1 ? "border-2 border-primary shadow" : "border border-grey"
				}`}>
				<div className="h-full w-full text-center">
					{hovering && (
						<span className="absolute top-0 inset-x-0 bg-grey-darker text-white text-center p-4 opacity-75 truncate z-10">
							{animation.name}
						</span>
					)}
					<Animation animation={animation} onEnded={() => setPlaying(false)} />
				</div>

				{hovering && mode === MediaLibrary.MODE_LIBRARY && (
					<>
						<a
							href={animation.download_url}
							onClick={e => e.stopPropagation()}
							className="absolute bottom-0 left-0 bg-grey-darker p-1 pb-0 opacity-75 cursor-pointer">
							<EvaIcon type="download-outline" width="24" height="24" fill="white" />
						</a>
						<button
							onClick={e => {
								e.stopPropagation();
								togglePlayState();
							}}
							style={{ left: "35px" }}
							className="absolute bottom-0 bg-grey-darker p-1 pb-0 opacity-75 cursor-pointer">
							<EvaIcon type={`${playing ? "pause" : "play"}-circle-outline`} width="24" height="24" fill="white" />
						</button>
						<button
							onClick={e => {
								e.stopPropagation();
								setEditing(true);
							}}
							className="absolute bottom-0 bg-grey-darker p-1 pb-0 opacity-75 cursor-pointer"
							style={{ right: "35px" }}>
							<EvaIcon type="edit-outline" width="24" height="24" fill="white" />
						</button>
						<button
							onClick={e => {
								e.stopPropagation();
								startDelete();
							}}
							className="absolute right-0 bottom-0 bg-grey-darker p-1 pb-0 opacity-75 cursor-pointer">
							<EvaIcon type="trash-2-outline" width="24" height="24" fill="white" />
						</button>
					</>
				)}
			</div>

			{mode === MediaLibrary.MODE_LIBRARY && (
				<>
					<MediaLibraryMediaDialog
						isOpen={editing}
						media={animation}
						isAnimation
						onCancel={() => setEditing(false)}
						onConfirm={() => {
							setEditing(false);
							refreshMedia();
						}}
					/>

					<DeleteDialog
						isOpen={deleting}
						onCancel={() => setDeleting(false)}
						onConfirm={handleDelete}
						deleteData={{
							message: uctrans(
								"general.confirm_delete_:item_:label",
								{ label: animation.name },
								{ item: "media.animation.singular" },
							),
							errors: [],
							warnings: deleteWarnings || [],
						}}
					/>
				</>
			)}
		</div>
	);
}

MediaLibraryAnimation.propTypes = {
	animation: PropTypes.object.isRequired,
};
