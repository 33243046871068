import { useContext } from "react";
import MediaLibraryContext from "./MediaLibraryContext";
import MediaLibraryDocument from "./MediaLibraryDocument";

export default function MediaLibraryDocuments() {
	const { media } = useContext(MediaLibraryContext);

	return (
		<div className="mt-4">
			{media.map(document => (
				<MediaLibraryDocument key={document.id} document={document} />
			))}
		</div>
	);
}
