import { route } from "../../../../../../js/helpers";
import { createLoader, error } from "../../../../../../js/react/components/general/notifications";
import axios from "axios";
import forEach from "lodash/forEach";
import upperFirst from "lodash/upperFirst";
import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import ConfirmDialog from "../../../../../../js/react/components/general/ConfirmDialog";
import Translate from "../../../../../../js/react/components/general/Translate";
import MediaLibraryContext from "./MediaLibraryContext";

export default function MediaLibraryFolderDialog(props) {
	const { type } = useContext(MediaLibraryContext);

	const editing = typeof props.folder !== "undefined";
	const [folder, setFolder] = useState(editing ? props.folder : { name: "" });

	const setFolderField = useCallback(
		(field, value) => {
			const updatedFolder = { ...folder };
			updatedFolder[field] = value;
			setFolder(updatedFolder);
		},
		[folder],
	);

	useEffect(() => {
		setFolder(editing ? props.folder : { name: "" });
	}, [editing, props.folder]);

	const handleConfirm = useCallback(async () => {
		const loader = createLoader(uctrans("general.save_:item", {}, { item: "media.mediafolder.singular" }));

		try {
			if (editing) {
				await axios.put(route(`crm.media_folder.update`, folder.id), { ...folder, type });
			} else {
				await axios.post(route(`crm.media_folder.store`), { ...folder, type });
			}

			loader.success(uctrans("general.saved_:item", {}, { item: "media.mediafolder.singular" }));
			props.onConfirm();
		} catch (exception) {
			if (exception.response && exception.response.status === 422) {
				if (Object.prototype.hasOwnProperty.call(exception.response.data, "errors")) {
					let first = true;
					forEach(exception.response.data.errors, message => {
						if (first && loader) {
							loader.failure(upperFirst(message[0]));
							first = false;
						} else {
							error(upperFirst(message[0]));
						}
					});
				}
			} else if (exception.message) {
				loader.failure(exception.message);
			}
			props.onCancel();
		}
	}, [editing, folder, props, type]);

	return (
		<ConfirmDialog
			isOpen={props.isOpen}
			onConfirm={handleConfirm}
			onCancel={props.onCancel}
			title={uctrans(editing ? "general.edit_:item" : "general.add_:item", {}, { item: "media.mediafolder.singular" })}
			confirmButtonLabel={uctrans("general.save_:item", {}, { item: "media.mediafolder.singular" })}
			cancelButtonLabel={uctrans("general.cancel")}>
			<label htmlFor="folder_name">
				<Translate content="general.name" />
			</label>
			<input
				id="folder_name"
				type="text"
				defaultValue={editing ? props.folder.name : ""}
				onChange={event => setFolderField("name", event.target.value)}
			/>
		</ConfirmDialog>
	);
}

MediaLibraryFolderDialog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	folder: PropTypes.object,
	onCancel: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
};
