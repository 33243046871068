import PropTypes from "prop-types";
import { memo } from "react";

export const Tab = memo(props =>
	props.visible ? (
		<li
			className={`tab-item ${props.isActive ? "active" : ""} ${props.isDisabled ? "disabled" : ""}`}
			onClick={() => {
				if (props.onTabClick) {
					props.onTabClick();
				}
			}}>
			<a
				className="tab-link"
				onClick={event => {
					event.preventDefault();
					props.onClick(props.tabIndex);
				}}>
				{props.title}
			</a>
		</li>
	) : null,
);

Tab.displayName = "Tab";

Tab.propTypes = {
	onClick: PropTypes.func,
	onTabClick: PropTypes.func,
	tabIndex: PropTypes.number,
	isActive: PropTypes.bool,
	title: PropTypes.string.isRequired,
	visible: PropTypes.bool,
	isDisabled: PropTypes.bool,
};

Tab.defaultProps = {
	visible: true,
};
